import React, { Component } from 'react'


export default class Footer extends Component {

    render(){
        return(
            <div className='footer'>
            <h3 >Questions? Concerns? Ideas?  Contact our email: esgduke@gmail.com</h3>
            </div>
        )
    }
}